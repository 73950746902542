import { Footer } from '@app/components/Footer'
import { Card, Center, Image } from '@mantine/core'
import Head from 'next/head'
import { type FC, type ReactNode } from 'react'

export interface AuthLayoutProps {
  children?: ReactNode
  title: string
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, title }) => (
  <Center sx={{ height: '100vh', width: '100vw' }}>
    <Head>
      <title>{`Swap PM / ${title}`}</title>
    </Head>
    <Card
      p='lg'
      radius='md'
      shadow='md'
      w={350}
      withBorder
    >
      <Card.Section
        mb='sm'
        p='lg'
      >
        <Image
          alt='SWAP PM'
          src='/logo.svg'
          width='100%'
        />
      </Card.Section>
      {children}
    </Card>
    <Footer />
  </Center>
)
